import { I18n } from '@aws-amplify/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';

import { FileListRequest, DeleteFileRequest, DownloadFileRequest } from './types/request';
import { FileListDTO } from './types/response';
import { FileService } from './services';

const fileService = new FileService();

export function useFileSummaryList({ organization_id, company_id, filter }: FileListRequest) {
	const summaryList = useQuery(
		['file_summary_list', [organization_id, company_id, filter]],
		() => fileService.getFileSummaryList({ organization_id, company_id, filter }),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...summaryList,
		data: summaryList.data ?? ({} as FileListDTO)
	};
}

export const useDownloadFile = () => {
	return useMutation((body: DownloadFileRequest) => fileService.downloadFile(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: (data) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
			message.success(I18n.get('Download successfully'));
		}
	});
};

export const useDeleteFile = () => {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteFileRequest) => fileService.deleteFileReport(body), {
		onError: (err: any) => {
			const typeError = err.response.data.details[0].split('" ');
			notification.error({
				message: I18n.get('Ops... something happened!'),
				description: I18n.get(typeError[1])
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['file_summary_list']);
			message.success(I18n.get('File deleted successfully'));
		}
	});
};
