import styled from 'styled-components';
import { Col } from 'antd';

export const ColCustom = styled(Col)`
	.ant-tabs-nav {
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				width: 99%;
				.ant-tabs-tab-active {
					border-bottom: #2f54eb solid 3px !important;
				}
			}

			.ant-tabs-nav-operations {
				display: none !important;
			}

			.ant-tabs-tab {
				flex-grow: 1;
				margin-right: 0px;
				width: 100%;
				text-align: center;
				.ant-tabs-tab-btn {
					width: 100%;
				}
			}
		}
	}
`;
