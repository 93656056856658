import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ResultCard, RiskCard, HighestRpn, RpnCard, DescriptionCard } from './styles';
import { calculateResultForScore, riskColors, riskLevels } from '@/utils/riskRange';
import { Text, Title } from '@/components/Typography';
import { Risk } from '@/hooks';

interface FinalResultsProps {
	worstScore: number;
	worstValues: Risk[];
}

export const FinalResults: React.FC<FinalResultsProps> = ({ worstScore, worstValues }) => {
	const digitManipulated = ('000000' + (worstScore ?? 0)).slice(-6);

	return (
		<Row gutter={[0, 40]}>
			<Col span={24}>
				<Title level={5}>Sum of RPNs</Title>
				<ResultCard>{digitManipulated}</ResultCard>
			</Col>
			<Col span={24}>
				<Title level={5}>Higher RPNs</Title>
				<Row gutter={[0, 10]}>
					{worstValues.map(({ id, description, score }) => (
						<Col key={id} span={23}>
							<HighestRpn>
								<RiskCard xs={4} xxl={3} $cardColor={riskColors[calculateResultForScore(score)]}>
									{I18n.get(riskLevels[calculateResultForScore(score)])}
								</RiskCard>
								<RpnCard xs={4} xxl={3}>
									<Text strong>RPN</Text>
									{': '}
									{score}
								</RpnCard>
								<DescriptionCard xs={16} xxl={18}>
									<Text strong>Found at:</Text>
									{I18n.get(`${description}`)}
								</DescriptionCard>
							</HighestRpn>
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
};
