import React, { useContext, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import { Col, Table } from 'antd';

import hooks from '../hooks';
import { MergeAEPContext } from '../context';
import { Spinner } from '@/components/Spinner';
import { useApplicationContext } from '@/context/v1/Application/context';

type TableDataType = {
	id: string;
	file_name: string;
	analyst: string;
	score: number;
};

const { useGetPEAList } = hooks;

export const TableList = () => {
	const [pageSize, setPageSize] = useState<number>(5);
	const { organization, company } = useApplicationContext();

	const {
		data: { pea_list }
	} = useContext(MergeAEPContext);

	function handleShowSizeChange(size: number): void {
		setPageSize(size);
	}

	const {
		data: PEAList,
		isLoading,
		isError
	} = useGetPEAList(
		{
			companyId: company.id,
			organizationId: organization.id
		},
		0,
		100
	);

	const columns: ColumnsType<TableDataType> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'file_name',
			key: 'key',
			align: 'center',
			ellipsis: true,
			render: (text: string) => text.split('-')[0]
		},
		{
			title: I18n.get('Analyst'),
			dataIndex: 'analyst',
			key: 'analyst',
			align: 'center',
			ellipsis: true
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			ellipsis: true
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	const dataSource: TableDataType[] = PEAList.result
		.filter((pea) => pea_list?.some((selectedPEA) => selectedPEA === pea.id))
		.map((pea) => ({
			id: pea.id,
			file_name: pea.file_name,
			analyst: pea.analyst_name,
			score: pea.worst_score
		}));

	return (
		<Col span={24}>
			<Table
				pagination={{
					pageSize,
					defaultPageSize: 5,
					total: PEAList.total,
					showSizeChanger: true,
					hideOnSinglePage: false,
					pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
					onShowSizeChange: (_, size) => handleShowSizeChange(size)
				}}
				rowKey="key"
				dataSource={dataSource}
				columns={columns}
			/>
		</Col>
	);
};
