import Api from '@/services/api';
import type { FileListDTO } from './types/response';
import type { FileListRequest, DeleteFileRequest, DownloadFileRequest } from './types/request';

export class FileService {
	async getFileSummaryList({ organization_id, company_id, filter }: FileListRequest): Promise<FileListDTO> {
		const params = { ...filter, organization_id, company_id };
		const { data } = await Api.get<FileListDTO>('/upload/files', { params });
		return data;
	}

	async downloadFile(body: DownloadFileRequest): Promise<string> {
		const url = `upload/create-url-download`;
		const { data } = await Api.post<string>(url, body);
		return data;
	}

	async deleteFileReport(body: DeleteFileRequest): Promise<{ file_id: string }> {
		const url = `upload/files/delete`;
		const { data } = await Api.delete<{ file_id: string }>(url, { params: body });
		return data;
	}
}
