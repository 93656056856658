import {
	MIN_HIGH_RISK_RPN,
	HIGH_RISK_RPN_COLOR,
	MIN_SERIOUS_RISK_RPN,
	MIN_MODERATE_RISK_RPN,
	MIN_VERY_HIGH_RISK_RPN,
	DEFAULT_RISK_RPN_COLOR,
	SERIOUS_RISK_RPN_COLOR,
	MODERATE_RISK_RPN_COLOR,
	VERY_HIGH_RISK_RPN_COLOR,
	ACCEPTABLE_RISK_RPN_COLOR
} from './constants';
import { CUSTOM_REPORT_RISKS } from './enum';

export const riskColors: Record<number, string> = {
	[CUSTOM_REPORT_RISKS.DEFAULT]: DEFAULT_RISK_RPN_COLOR,
	[CUSTOM_REPORT_RISKS.ACCEPTABLE]: ACCEPTABLE_RISK_RPN_COLOR,
	[CUSTOM_REPORT_RISKS.MODERATE]: MODERATE_RISK_RPN_COLOR,
	[CUSTOM_REPORT_RISKS.HIGH]: HIGH_RISK_RPN_COLOR,
	[CUSTOM_REPORT_RISKS.VERY_HIGH]: VERY_HIGH_RISK_RPN_COLOR,
	[CUSTOM_REPORT_RISKS.SERIOUS_IMMINENT]: SERIOUS_RISK_RPN_COLOR
};

export const riskLevels: { [key: number]: string } = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export function calculateResultForScore(score: number): number {
	if (score >= 179) {
		return 5;
	}

	if (score >= 124) {
		return 4;
	}

	if (score >= 35) {
		return 3;
	}

	if (score >= 8) {
		return 2;
	}

	return 1;
}

export function getResultRPN(score: number): number {
	if (score >= MIN_SERIOUS_RISK_RPN) {
		return CUSTOM_REPORT_RISKS.SERIOUS_IMMINENT;
	}

	if (score >= MIN_VERY_HIGH_RISK_RPN) {
		return CUSTOM_REPORT_RISKS.VERY_HIGH;
	}

	if (score >= MIN_HIGH_RISK_RPN) {
		return CUSTOM_REPORT_RISKS.HIGH;
	}

	if (score >= MIN_MODERATE_RISK_RPN) {
		return CUSTOM_REPORT_RISKS.MODERATE;
	}

	return CUSTOM_REPORT_RISKS.ACCEPTABLE;
}
