import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { Informations, WorkConditions, Characteristics, GenericStep, Results } from '../Report/Steps';
import type { EwaJdsD86ReportResponse, GetCustomReportByNameResponse, Step } from '@/hooks';
import { States, Context, Methods, EwaRouteParams } from './types';

const { useForm } = Form;

type EwaProviderProps = {
	children: ReactNode;
	ewa: EwaJdsD86ReportResponse;
	customReport: GetCustomReportByNameResponse;
};

type DefaultStepsMapper = {
	[key: string]: JSX.Element;
};

const EwaContext = createContext<Context>({} as Context);

const defaultSteps: DefaultStepsMapper = {
	informations: <Informations />,
	work_conditions: <WorkConditions />,
	characteristics: <Characteristics />
};

export const EwaProvider: React.FC<Readonly<EwaProviderProps>> = ({ children, ewa, customReport }) => {
	const [form] = useForm();
	const { file_id } = useParams<EwaRouteParams>();

	const [steps, setSteps] = useState<Step[]>([]);
	const [currentStep, setCurrentStep] = useState<number>(ewa.current_step ?? 0);

	const initialValues = {
		...ewa,
		informations: {
			...ewa.informations,
			collection_date: moment(ewa.informations.collection_date) ?? moment()
		}
	};

	useEffect(() => {
		const steps: Step[] = ewa.steps.map(({ id, name, description, sequence, step_keys }) => ({
			id,
			name,
			sequence,
			step_keys,
			description,
			component: defineComponent({ id, name, description, sequence, step_keys })
		}));

		setSteps(steps);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function defineComponent({ id, name, description, step_keys, sequence }: Step): JSX.Element {
		let defaultComponent = defaultSteps[name];

		if (!defaultComponent) {
			defaultComponent = (
				<GenericStep title={description} stepKeys={step_keys} stepId={id} stepNumber={sequence} />
			);
		}

		let component = defaultComponent;

		if (isResultStep(name)) {
			component = <Results />;
		}

		return component;
	}

	function isResultStep(name: string) {
		return name === 'results';
	}

	function scrollToError(error: any) {
		const errorName = error?.errorFields[0]?.name;
		form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get("Some required step wasn't filled"),
			duration: 5
		});
	}

	function handlePreviousStep() {
		setCurrentStep(currentStep - 1);
	}

	async function handleStepNagivationClick(step: number) {
		if (!ewa?.is_completed && step > currentStep + 1) {
			return;
		}
		if (step > currentStep) {
			await form.validateFields();
		}
		setCurrentStep(step);
	}

	async function handleNextStep() {
		setCurrentStep(currentStep + 1);
	}

	const currentStepId = steps[currentStep]?.id;

	const states: States = {
		ewa,
		steps,
		file_id,
		currentStep,
		customReport,
		currentStepId,
		lastGenericStepNumber: ewa.total_steps - 1
	};

	const methods: Methods = {
		scrollToError,
		handleNextStep,
		handlePreviousStep,
		handleStepNagivationClick
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<EwaContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</EwaContext.Provider>
	);
};

export function useEwaContext() {
	const context = useContext(EwaContext);
	return context;
}
