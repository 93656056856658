import { useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import type { AxiosError } from 'axios';
import { message } from 'antd';

import Api from '@/services/api';
import { CreateStepKeyResultDTO } from './useCreateStepKeyResults';
import type { BaseContext, CustomReportStepKeyResult, Response } from '@/types';

export type UpdateStepKeyResult = CreateStepKeyResultDTO & {
	step_key_result_id?: string;
};

export type UpdateStepKeyResultDTO = BaseContext & {
	step_id: string;
	custom_report_id: string;
	custom_report_result_id?: string;
	step_keys_results: UpdateStepKeyResult[];
};

async function updateStepKeyResults(body: UpdateStepKeyResultDTO): Promise<CustomReportStepKeyResult[]> {
	const url = '/custom-report/result/step-key';
	const { data } = await Api.put<CustomReportStepKeyResult[]>(url, body);
	return data;
}

export const useUpdateStepKeyResults = () => {
	const queryClient = useQueryClient();
	return useMutation<CustomReportStepKeyResult[], AxiosError<Response>, UpdateStepKeyResultDTO>(
		(data: UpdateStepKeyResultDTO) => updateStepKeyResults(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['jds_d86_report']);
				queryClient.invalidateQueries(['jds_d86_result']);
			},
			onError: (err) => {
				message.error(I18n.get(err?.response?.data?.message ?? err?.message));
			}
		}
	);
};
